<template>
  <div review v-editable="blok">
    <div class="review-avatar">
      <img v-if="blok.logo" :src="optimizeImage(blok.logo.filename)" :alt="`Review Author Logo ${blok.logo.alt}`" />
    </div>
    <div class="review-content" v-html="content"></div>
    <div class="review-footer">
      <div class="review-details">
        <div class="review-author" v-if="blok.author" v-text="blok.author"></div>
        <div class="review-citation" v-if="blok.citation" v-text="blok.citation"></div>
      </div>
    </div>
  </div>
</template>
<style src="~/assets/scss/section/review.scss" lang="scss"></style>
<script setup>
const props = defineProps({ blok: Object });
const content = computed(() => renderRichText(props?.blok?.content || {}));
</script>
